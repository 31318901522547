import React, { Suspense, lazy } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { Spinner } from "react-bootstrap";
import * as langServices from "./service/lang";
import es from "./components/lang/es.json";

const Home = lazy(() => import("./components/home"));
const Login = lazy(() => import("./components/login"));
const Register = lazy(() => import("./components/register"));
const PlayerSync = lazy(() => import("./components/playerSync"));
const Dashboard = lazy(() => import("./components/dashboard"));
const DashboardBeta = lazy(() => import("./components/dashboardBeta"));
const Players = lazy(() => import("./components/players"));
const PrivacyPolicy = lazy(() => import("./components/privacyPolicy"));
const TermsOfUse = lazy(() => import("./components/termsOfuse"));
const Streamers = lazy(() => import("./components/streamers"));
const Perks = lazy(() => import("./components/perks"));
const DailyChallenges = lazy(() => import("./components/dailyChallenges"));
const AccountSettings = lazy(() => import("./components/accountSettings"));
const LeaderBoard = lazy(() => import("./components/Leaderboard"));
const Contactus = lazy(() => import("./components/Contactus"));
const Aboutus = lazy(() => import("./components/aboutUs"));
const SupportedGame = lazy(() => import("./components/supportedGame"));
const SteamVerify = lazy(() => import("./components/steamVerify"));
const BattlenetVerify= lazy(() => import("./components/battlenetVerify"));
// const Membership = lazy(() => import("./components/Membership"));
const PageNotFound = lazy(() => import("./components/pageNotfound"));
const VerifyAuth = lazy(() => import("./components/verifyAuth"));
const CronTest = lazy(() => import("./components/cronTest"));
const Whitepaper = lazy(() => import("./components/whitePaper"));
const LibroBlanco = lazy(() => import("./components/libroBlanco"));

const GuidesDota2 = lazy(() => import("./components/guidesDota2"));
const GuidesPubg = lazy(() => import("./components/guidesPubg"));
const GuidesChess = lazy(() => import("./components/guidesChess"));
const GuidesAge4 = lazy(() => import("./components/guidesAge4"));
const GuidesAge2 = lazy(() => import("./components/guidesAge2"));
const GuidesStarcraft2 = lazy(() => import("./components/guidesStarcraft2"));
const GuidesInstallCrypto = lazy(() => import("./components/guidesInstallCrypto"));
const GuidesGetStart = lazy(() => import("./components/guideGetstart"));
const GuideDetails = lazy(() => import("./components/guideDetails"));
const GuidesSwap = lazy(() => import("./components/guidesSwap"));
const GuidesDeposit = lazy(() => import("./components/guidesDeposit"));
const GuidesWithdraw = lazy(() => import("./components/guidesWithdraw"));
const FaqDetails= lazy(() => import ("./components/faqDetails"))
const HelpFaq= lazy(() => import ("./components/helpFaq"))
const SearchFaq= lazy(() => import ("./components/searchFaq"))
const SearchGuide= lazy(() => import ("./components/searchGuide"))

const NoGames = lazy(() => import("./components/includes/noGames"));
const TwofactorVerification = lazy(() =>
  import("./components/twofactorVerification")
);
const Transactions = lazy(() => import("./components/transactions"));
const ForgotPassword = lazy(() => import("./components/forgotPassword"));
const VerifyOtp = lazy(() => import("./components/verifyOtp"));
const LeaderBoardall = lazy(() => import("./components/leaderboardAll"));
const DashboardDefault = lazy(() => import("./components/dashboardDefault"));
const FAQ = lazy(() => import("./components/faq"));
const Career = lazy(() => import("./components/career"));
const ResetPassword = lazy(() => import("./components/resetPassword"));
const AdminHome = lazy(() => import("./components/admin/home"));
const VerifyGamerTag = lazy(() => import("./components/admin/verifyGamerTag"));
const ListPlayers = lazy(() => import("./components/admin/listPlayers"));
const ListTransactions = lazy(() =>
  import("./components/admin/listTransactions")
);
const AdminTournaments = lazy(() => import("./components/admin/adminTournaments"));
const TournamentsParticipants = lazy(() => import("./components/admin/tournamentParticipants"));
const AdminEditTournaments = lazy(() => import("./components/admin/editTournament"));
const BettingLog = lazy(() => import("./components/admin/bettingLogs"));
const BettingStreamers = lazy(() =>
  import("./components/admin/bettingStreamers")
);
const BettingPVP = lazy(() => import("./components/admin/bettingPVP"));
const SteamUserEmail = lazy(() => import("./components/steamUserEmail"));
const EmailVerification = lazy(() => import("./components/emailVerification"));
const Affiliates = lazy(() => import("./components/affiliates"));
const BetaTest = lazy(() => import("./components/betaTest"));
const Competition = lazy(() => import("./components/competitions/competition"));
const Tournament = lazy(()=> import("./components/tournament/tournament"));
const TournamentDynamic = lazy(()=> import("./components/tournament/tournamentDynamic"));
const TournamentList = lazy(()=> import("./components/tournament/tournamentList"));
const Explore = lazy(() => import("./components/betting/explore"));
const BettingScoreboard = lazy(() =>
  import("./components/betting/bettingHistory")
);
// const BettingSummary = lazy(() =>
//   import("./components/betting/bettingSummary")
// );

const StreamerDashboard = lazy(() =>
  import("./components/betting/streamerDashboard")
);
const PlayToEarnDashboard = lazy(() =>
  import("./components/playToEarnDashboard")
);
const ListWithdraw = lazy(() => import("./components/admin/listWithdraw"));
const ListWithdrawPending = lazy(() => import("./components/admin/listWithdrawPending"));
const ManageSettings = lazy(() => import("./components/admin/manageSettings"));
const ManageNotification = lazy(() => import("./components/admin/manageNotification"));
const ManageFaq = lazy(() => import("./components/admin/manageFaq"));
const AddFaqCategory = lazy(() => import("./components/admin/addFaqCategory"));

const ManageGuide = lazy(() => import("./components/admin/manageGuide"));
const AddGuideCategory = lazy(() => import("./components/admin/addGuidesCategory"));

const GbGoogleAdd = lazy(() => import("./components/gbGoogleAdd"));
const ListLeaderboard = lazy(() =>
  import("./components/admin/listLeaderboard")
);
const ListDeposit = lazy(() => import("./components/admin/listDeposit"));
const ListFee = lazy(() => import("./components/admin/listFee"));
const ListFailed = lazy(() => import("./components/admin/listFailed"));
const UserStatistics = lazy(() => import("./components/admin/userStatistics"));
const NewCreateChallenge = lazy(() =>
  import("./components/betting/newCreateChallenge")
);
const BetHistory = lazy(() => import("./components/admin/betHistory"));
const PendingRequest = lazy(() => import("./components/admin/pendingRequests"));
const BetYourselfDetails = lazy(() =>
  import("./components/admin/betYourselfDetails")
);
const ResolveDispute = lazy(() => import("./components/admin/resolveDispute"));
const CreateBetStreamer = lazy(() =>
  import("./components/betting/createBetStreamer")
);
const SwapList=lazy(() => import("./components/admin/swapList"));
const EditChallenges=lazy(() => import("./components/admin/editChallenges"));
const EditSBChallenges=lazy(() => import("./components/admin/editSBChallenges"));
const MyFriends=lazy(() => import("./components/myFriends"));

const Hardcore=lazy(() => import("./components/admin/harcoreModeSetting"));

/****************************Redo Sec ********************/
const RedoLogin = lazy(() => import("./components/redo/login"));
const RedoRegister = lazy(() => import("./components/redo/register"));
const RedoTwoFactor = lazy(() => import("./components/redo/twoFactor"));
const RedoDashboard = lazy(() => import("./components/redo/dashboard"));
const RedoTournaments = lazy(() => import("./components/redo/tournaments"));
const RedoSettings = lazy(() => import("./components/redo/settings"));
const RedoCreateBetYourself = lazy(() => import("./components/redo/createBetYourself"));
const RedoCreateBetOnStreamer = lazy(() => import("./components/redo/createBetOnStreamer"));
const RedoTournamentLanding = lazy(() => import("./components/redo/common/tournaments/tournamentLanding"));
const RedoCreateTournament = lazy(() => import("./components/redo/createTournaments"));
const RedoTransactions = lazy(() => import("./components/redo/transactions"));
const RedoMyFriends = lazy(() => import("./components/redo/myFriends"));
const RedoAffiliates = lazy(() => import("./components/redo/affiliates"));
const RedoSteamVerify = lazy(() => import("./components/redo/steamVerify"));
const RedoSteamUserEmail = lazy(() => import("./components/redo/steamUserEmail"));
const RedoBattlenetVerify= lazy(() => import("./components/redo/battlenetVerify"));
const RedoExplore = lazy(() => import("./components/redo/common/betting/explore"));
const RestorePassword = lazy(() => import("./components/redo/restorePassword"));
const RedoPageNotFound = lazy(() => import("./components/redo/pageNotFound"));
const RedoUserTournaments = lazy(() => import("./components/redo/userTournament"));
const RedoPlayerSync = lazy(() => import("./components/redo/playerSync"));
const ListMissedDota2Matches = lazy(() => import("./components/admin/listMissedDota2Matches"));
const ListMissedPubgMatches = lazy(() => import("./components/admin/listMissedPubgMatches"));
const RedoEmailVerification = lazy(() => import("./components/redo/emailVerification"));
const RedoDailyChallenges = lazy(() => import("./components/redo/dailyChallenges"));

/****************************Redo Sec ********************/
const AdminDailyChallenges = lazy(() => import("./components/admin/adminDailyChallenges"));
const DiscordOauth = lazy(() => import("./components/redo/common/discordOauth"));

const SimMatchReport = lazy(() => import("./components/admin/simulationMatchReport"));

const App = () => {
  const images = require.context("./assets/images", true);

  const images_redo  = require.context("./assets/images/redo", true);
  const renderSwitch = (text) => {
    const currentLang = langServices.getGameLang();
    switch (currentLang) {
      case "es":
        return es[text] ? es[text] : text;
      default:
        return text;
    }
  };

  var sitelockPass = true;
  if (
    localStorage.getItem("_GB_Sitelockv1") === null ||
    localStorage.getItem("_GB_Sitelockv1") === false
  ) {
    sitelockPass = false;
  } else {
    sitelockPass = true;
  }
  if (sitelockPass === false) {
    return (
      <React.Fragment>
        <form
          style={{
            marginLeft: "30%",
            marginRight: "30%",
            marginTop: 50,
          }}
          id="sitelock"
          onSubmit={(e) => {
            e.preventDefault();
            const password = document.getElementById("password").value;
            if (password === "gamebeef2022") {
              localStorage.setItem("_GB_Sitelockv1", true);
              window.location = "/";
            }
          }}
        >
          <div className="form-group d-flex">
            <input
              type="password"
              placeholder="Enter code to proceed"
              className="form-control"
              id="password"
            />
            <button type="submit" className="btn btn-primary ml-2">
              Submit
            </button>{" "}
          </div>
        </form>
      </React.Fragment>
    );
  } else {
    return (
      <Router>
        <Suspense
          fallback={
            <div>
              <div className="_GB_Register _initPage">
                <div className="register_wrap d-flex">
                  <div className="register_left col-12 d-flex flex-column justify-content-center">
                    <div className="register_left_inner">
                      <div className="mb-4">
                        <img
                          src={images_redo("./mob_gblogo_sg.svg")}
                          alt="Game Beef"
                          className="img-fluid"
                        />
                      </div>
                      <div className="GB_page_loader">
                        <Spinner animation="border" variant="light" size="sm" />
                        <span>{renderSwitch("Loading")}...</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          }
        >
          <Routes>
            {/* <Route path="/" element={<Home />} />
            <Route path="/:lang" element={<Home />} /> */}

            {/* <Route path="/login" element={<Login />} />
            <Route path="/:lang/login" element={<Login />} />

            <Route path="/register" element={<Register />} />
            <Route path="/:lang/register" element={<Register />} /> */}

            {/* <Route path="/player-sync" element={<PlayerSync />} />
            <Route path="/:lang/player-sync" element={<PlayerSync />} /> */}

            {/* <Route path="/dashboard" element={<DashboardDefault />} />
            <Route path="/:lang/dashboard" element={<DashboardDefault />} /> */}
            {/* <Route path="/dashboard/:game" element={<Dashboard />} />
            <Route path="/:lang/dashboard/:game" element={<Dashboard />} /> */}
            {/* <Route path="/dashboard/:game" element={<DashboardBeta />} />
            <Route path="/:lang/dashboard/:game" element={<DashboardBeta />} /> */}

            {/* <Route path="/dashboard/:game" element={<DashboardBeta />} />
            <Route path="/:lang/dashboard/:game" element={<DashboardBeta />} /> */}

            {/* <Route path="/dashboard/:game" element={<PlayToEarnDashboard />} />
            <Route
              path="/:lang/dashboard/:game"
              element={<PlayToEarnDashboard />}
            />

            <Route path="/players" element={<Players />} />
            <Route path="/:lang/players" element={<Players />} /> */}

            <Route path="/privacy-policy" element={<PrivacyPolicy />} />
            <Route path="/:lang/privacy-policy" element={<PrivacyPolicy />} />

            <Route path="/terms-of-use" element={<TermsOfUse />} />
            <Route path="/:lang/terms-of-use" element={<TermsOfUse />} />

            {/* <Route path="/streamers" element={<Streamers />} />
            <Route path="/:lang/streamers" element={<Streamers />} />

            <Route path="/perks/:game" element={<Perks />} />
            <Route path="/:lang/perks/:game" element={<Perks />} />
            <Route path="/noGames" element={<NoGames />} />
            <Route
              path="/daily-challenges/:game"
              element={<DailyChallenges />}
            />
            <Route
              path="/:lang/daily-challenges/:game"
              element={<DailyChallenges />}
            />

            <Route
              path="/account-settings/:account_tab"
              element={<AccountSettings />}
            />
            <Route
              path="/:lang/account-settings/:account_tab"
              element={<AccountSettings />}
            />

            <Route path="/contact-us" element={<Contactus />} />
            <Route path="/:lang/contact-us" element={<Contactus />} />

            <Route path="/about-us" element={<Aboutus />} />
            <Route path="/:lang/about-us" element={<Aboutus />} />

            <Route path="/supported-games" element={<SupportedGame />} />
            <Route path="/:lang/supported-games" element={<SupportedGame />} />

            <Route path="/guides-dota2" element={<GuidesDota2/>} />
            <Route path="/:lang/guides-dota2" element={<GuidesDota2 />} />

            <Route path="/guides-pubg" element={<GuidesPubg/>} />
            <Route path="/:lang/guides-pubg" element={<GuidesPubg />} />

            <Route path="/guides-chess" element={<GuidesChess/>} />
            <Route path="/:lang/guides-pubg" element={<GuidesChess />} />

            <Route path="/guides-ageofempires4" element={<GuidesAge4/>} />
            <Route path="/:lang/guides-ageofempires4" element={<GuidesAge4 />} />

            <Route path="/guides-ageofempires2" element={<GuidesAge2/>} />
            <Route path="/:lang/guides-ageofempires2" element={<GuidesAge2 />} />

            <Route path="/guides-withdraw" element={<GuidesWithdraw/>} />
            <Route path="/:lang/guides-withdraw" element={<GuidesWithdraw />} />

            <Route path="/guides-starcraft2" element={<GuidesStarcraft2/>} />
            <Route path="/:lang/guides-ageofempires2" element={<GuidesStarcraft2 />} />
           
            <Route path="/guides" element={< GuidesGetStart/>} />
            <Route path="/:lang/guides" element={< GuidesGetStart />} />

            <Route path="/guide/:slug" element={< GuideDetails/>} />
            <Route path="/:lang/guide/:slug" element={< GuideDetails />} />

            <Route path="/guides-deposit" element={< GuidesDeposit/>} />
            <Route path="/:lang/guides-deposit" element={< GuidesDeposit />} />

            <Route path="/guides-swap" element={<GuidesSwap/>} />
            <Route path="/:lang/guides-swap" element={<GuidesSwap />} />


            <Route path="/guides-install-crypto" element={<GuidesInstallCrypto/>} />
            <Route path="/:lang/guides-install-crypto" element={<GuidesInstallCrypto />} />

            <Route path="/steam_verify" element={<SteamVerify />} />
            <Route path="/:lang/steam_verify" element={<SteamVerify />} /> */}

            {/* <Route path="/battlenet/oauth" element={<BattlenetVerify />} /> */}
  

            {/* <Route path="/membership" element={<Membership />} /> */}
            <Route path="/verifyAuth/:connect" element={<VerifyAuth />} />
            <Route path="/:lang/verifyAuth/:connect" element={<VerifyAuth />} />

            <Route path="/cron/stats/:game" element={<CronTest />} />

            <Route path="/google-add" element={<GbGoogleAdd />} />
            <Route path="/:lang/google-add" element={<GbGoogleAdd />} />

            <Route path="/whitepaper" element={<Whitepaper />} />
            <Route path="/:lang/whitepaper" element={<LibroBlanco />} />
            <Route path="/libro-blanco" element={<LibroBlanco />} />

            {/* <Route
              path="/twofactor-verification"
              element={<TwofactorVerification />}
            />
            <Route
              path="/:lang/twofactor-verification"
              element={<TwofactorVerification />}
            />

            <Route path="/transactions" element={<Transactions />} />
            <Route path="/:lang/transactions" element={<Transactions />} />

            <Route path="/forgot-password" element={<ForgotPassword />} />
            <Route path="/:lang/forgot-password" element={<ForgotPassword />} />

            <Route path="/reset-password/:id" element={<ResetPassword />} />
            <Route
              path="/:lang/reset-password/:id"
              element={<ResetPassword />}
            /> */}

            <Route path="/verify-otp/:id" element={<VerifyOtp />} />
            <Route path="/:lang/verify-otp/:id" element={<VerifyOtp />} />

            {/* <Route path="/faq" element={<FAQ />} />
            <Route path="/:lang/faq" element={<FAQ />} />
            <Route path="/faq/:type" element={<FAQ />} />
            <Route path="/:lang/faq/:type" element={<FAQ />} />
            <Route path="/faq/:type/:slug" element={<FAQ />} />
            <Route path="/:lang/faq/:type/:slug" element={<FAQ />} /> */}

            {/* <Route path="/faq-details" element={< FaqDetails/>} />
            <Route path="/:lang/faq-details" element={< FaqDetails />} /> */}

            {/* <Route path="/help-faq" element={< HelpFaq/>} />
            <Route path="/:lang/help-faq" element={< HelpFaq />} /> */}

            {/* <Route path="/faq" element={<HelpFaq />} />
            <Route path="/:lang/faq" element={<HelpFaq />} />
            <Route path="/faq/:slug" element={<FaqDetails />} />
            <Route path="/:lang/faq/:slug" element={<FaqDetails />} />
            <Route path="/searchfaq" element={<SearchFaq />} />
            <Route path="/:lang/searchfaq" element={<SearchFaq />} />
             <Route path="/searchGuide" element={<SearchGuide />} />
            <Route path="/:lang/searchGuide" element={<SearchGuide />} />

            <Route path="/career" element={<Career />} />
            <Route path="/:lang/career" element={<Career />} /> */}

            <Route path="/admin" element={<ListPlayers />} />
            <Route path="/admin/verify-gamertag" element={<VerifyGamerTag />} />
            <Route path="/admin/list-players" element={<ListPlayers />} />
            <Route path="/admin/list-withdraw" element={<ListWithdraw />} />
            <Route path="/admin/list-withdraw-pending" element={<ListWithdrawPending />} />
            <Route path="/admin/edit-challenges" element={<EditChallenges />} />
            <Route path="/admin/edit-streamers-challenges" element={<EditSBChallenges />} />
            <Route path="/admin/manage-settings" element={<ManageSettings />} />
            <Route path="/admin/manage-notifications" element={<ManageNotification />} />
            <Route path="/admin/manage-faq" element={<ManageFaq />} />
            <Route path="/admin/manage-faq-category" element={<AddFaqCategory />} />
            <Route path="/admin/manage-guide" element={<ManageGuide />} />
            <Route path="/admin/manage-guide-category" element={<AddGuideCategory />} />
            <Route path="/admin/leaderboard" element={<ListLeaderboard />} />
            <Route path="/admin/transactions" element={<ListTransactions />} />
            <Route path="/admin/list-deposit" element={<ListDeposit />} />
            <Route path="/admin/user-statistics" element={<UserStatistics />} />
            <Route path="/admin/betting-logs" element={<BettingLog />} />
            <Route path="/es/admin/betting-logs" element={<BettingLog />} />
            <Route
              path="/admin/betting-streamers"
              element={<BettingStreamers />}
            />
            <Route path="/admin/betting-pvp" element={<BettingPVP />} />
            <Route path="/admin/list-fee" element={<ListFee />} />
            <Route path="/admin/list-failed" element={<ListFailed />} />
            <Route path="/admin/swap-list" element={<SwapList/>} />
            <Route
              path="/admin/pending-requests"
              element={<PendingRequest />}
            />
            <Route path="/admin/tournaments" element={<AdminTournaments />} />
            <Route path="/admin/tournament/participants/:id" element={<TournamentsParticipants />} />
             {/* <Route path="/admin/tournament/edit/:id" element={<AdminEditTournaments />} /> */}
            <Route path="/admin/bet-history" element={<BetHistory />} />
            <Route path="/es/admin/bet-history" element={<BetHistory />} />
            <Route
              path="/admin/bet-details/:ticket"
              element={<BetYourselfDetails />}
            />
            <Route
              path="/es/admin/bet-details/:ticket"
              element={<BetHistory />}
            />
            <Route path="/admin/dispute/:ticket" element={<ResolveDispute />} />
            <Route
              path="/es/admin/dispute/:ticket"
              element={<ResolveDispute />}
            />
            <Route
              path="/admin/hardcoreModeSetting"
              element={<Hardcore/>}
            />
            {/* <Route path="/steam-user-email" element={<SteamUserEmail />} /> */}

            {/* <Route
              path="/:lang/steam-user-email"
              element={<SteamUserEmail />}
            /> */}

            {/* <Route
              path="/email-verification/:id"
              element={<EmailVerification />}
            />*/}
            <Route
              path="/:lang/email-verification/:id"
              element={<EmailVerification />}
            /> 
{/* 
            <Route path="/affiliates" element={<Affiliates />} />
            <Route path="/:lang/affiliates" element={<Affiliates />} />

            <Route path="/vip" element={<MyFriends />} />
            <Route path="/:lang/vip" element={<MyFriends />} />

            <Route path="/top-earners" element={<LeaderBoard />} />
            <Route path="/:lang/top-earners" element={<LeaderBoard />} />
            <Route path="/top-earners/:game" element={<LeaderBoard />} />
            <Route path="/:lang/top-earners/:game" element={<LeaderBoard />} />

            <Route path="/leaderboard" element={<LeaderBoardall />} />
            <Route path="/:lang/leaderboard" element={<LeaderBoardall />} />

            <Route path="/leaderboard/:game" element={<Competition />} />
            <Route path="/:lang/leaderboard/:game" element={<Competition />} /> */}

            
            {/* <Route path="/create-tournament" element={<Tournament />} />
            <Route path="/:lang/create-tournament" element={<Tournament />} />
            <Route path="/tournament/:id" element={<TournamentDynamic />} />
            <Route path="/:lang/tournament/:id" element={<TournamentDynamic />} />
            <Route path="/tournaments" element={<TournamentList />} />
            <Route path="/:lang/tournaments" element={<TournamentList />} /> */}

            <Route path="/sebat0" element={<RedoRegister />} />
            <Route path="/:lang/sebat0" element={<RedoRegister />} />
            <Route path="/facku" element={<RedoRegister />} />
            <Route path="/:lang/facku" element={<RedoRegister />} />
            <Route path="/juanndo0" element={<RedoRegister />} />
            <Route path="/:lang/juanndo0" element={<RedoRegister />} />
            <Route path="/r/:ref" element={<RedoRegister />} />
            <Route path="/:lang/r/:ref" element={<RedoRegister />} />

            {/* <Route path="/beta-test" element={<BetaTest />} /> */}

            {/* <Route path="/betting/explore" element={<Explore />} />
            <Route path="/:lang/betting/explore" element={<Explore />} /> */}

            <Route path="/betting/:channel" element={<BettingScoreboard />} />
            <Route path="/:lang/betting/:channel" element={<BettingScoreboard />} />

            {/* <Route path="/betting/summary" element={<BettingSummary />} />
            <Route path="/:lang/betting/summary" element={<BettingSummary />} /> */}

            <Route
              path="/betting/streamer-dashboard"
              element={<StreamerDashboard />}
            />
            <Route
              path="/:lang/betting/streamer-dashboard"
              element={<StreamerDashboard />}
            />
            {/* <Route
              path="/betting/create-challenge"
              element={<NewCreateChallenge />}
            />
            <Route
              path="/:lang/betting/create-challenge"
              element={<NewCreateChallenge />}
            />
            <Route
              path="/betting/create-bet-streamer"
              element={<CreateBetStreamer />}
            />
            <Route
              path="/:lang/betting/create-bet-streamer"
              element={<CreateBetStreamer />}
            />
            <Route
              path="/play-to-earn-dashboard"
              element={<PlayToEarnDashboard />}
            /> */}
            {/* <Route path="/pageNotFound" element={<PageNotFound />} />
            <Route path="*" element={<PageNotFound />} /> */}

            /****************************Redo Sec ********************/
            <Route path="/" element={<RedoLogin />} />
            <Route path="/:lang" element={<RedoLogin />} />
            <Route path="/login" element={<RedoLogin />} />
            <Route path="/:lang/login" element={<RedoLogin />} />
            <Route path="/register" element={<RedoRegister />} />
            <Route path="/:lang/register" element={<RedoRegister />} />
            <Route path="/two-factor-verification" element={<RedoTwoFactor />} />
            <Route path="/:lang/two-factor-verification" element={<RedoTwoFactor />} /> 

            <Route path="/dashboard" element={<RedoDashboard />} />
            <Route path="/:lang/dashboard" element={<RedoDashboard />} /> 
            <Route path="/dashboard/:user_game" element={<RedoDashboard />} />
            <Route path="/:lang/dashboard/:user_game" element={<RedoDashboard />} /> 

            <Route path="/dashboard/bets" element={<RedoDashboard />} />
            <Route path="/:lang/dashboard/bets" element={<RedoDashboard />} /> 
            <Route path="/dashboard/bets/:user_game" element={<RedoDashboard />} />
            <Route path="/:lang/dashboard/bets/:user_game" element={<RedoDashboard />} /> 

            <Route path="/tournaments" element={<RedoTournaments />} />
            <Route path="/:lang/tournaments" element={<RedoTournaments />} /> 
            <Route path="/tournament/:id" element={<RedoTournamentLanding />} />
            <Route path="/:lang/tournament/:id" element={<RedoTournamentLanding />} /> 
            {/* <Route path="/account-settings" element={<RedoSettings />} />
            <Route path="/:lang/account-settings" element={<RedoSettings />} />  */}
            <Route path="/account-settings/:account_tab" element={<RedoSettings />} />
            <Route path="/:lang/account-settings/:account_tab" element={<RedoSettings />} /> 
            <Route path="/create-bet-against-yourself" element={<RedoCreateBetYourself />} />
            <Route path="/:lang/create-bet-against-yourself" element={<RedoCreateBetYourself />} /> 
            <Route path="/create-bet-on-streamer" element={<RedoCreateBetOnStreamer />} />
            <Route path="/:lang/create-bet-on-streamer" element={<RedoCreateBetOnStreamer />} /> 
            <Route path="/create-tournament" element={<RedoCreateTournament />} />
            <Route path="/:lang/create-tournament" element={<RedoCreateTournament />} /> 
            <Route path="/transactions" element={<RedoTransactions />} />
            <Route path="/:lang/transactions" element={<RedoTransactions />} /> 
            <Route path="/vip-list" element={<RedoMyFriends />} /> 
            <Route path="/:lang/vip-list" element={<RedoMyFriends />} /> 
            <Route path="/affiliates" element={<RedoAffiliates />} />
            <Route path="/:lang/affiliates" element={<RedoAffiliates />} /> 
            <Route path="/steam_verify" element={<RedoSteamVerify/>} />
            <Route path="/:lang/redo/steam_verify" element={<RedoSteamVerify />} />
            <Route path="/steam-user-email" element={<RedoSteamUserEmail />} />
            <Route path="/:lang/steam-user-email" element={<RedoSteamUserEmail />} />
            <Route path="/battlenet/oauth" element={<RedoBattlenetVerify />} />
            <Route path="/betting/explore" element={<RedoExplore />} />
            <Route path="/:lang/betting/explore" element={<RedoExplore />} />
            <Route path="/forgot-password" element={<RestorePassword />} />
            <Route path="/:lang/forgot-password" element={<RestorePassword />} />
            <Route path="/pageNotFound" element={<RedoPageNotFound />} />
            <Route path="/404" element={<RedoPageNotFound />} />
            <Route path="*" element={<RedoPageNotFound />} />
            <Route path="/my-tournaments" element={<RedoUserTournaments />} />
            <Route path="/:lang/my-tournaments" element={<RedoUserTournaments />} /> 
            <Route path="/player-sync" element={<RedoPlayerSync />} />
            <Route path="/:lang/player-sync" element={<RedoPlayerSync />} />
            <Route path="/admin/list-missed-matches/dota2" element={<ListMissedDota2Matches/>} />
            <Route path="/admin/list-missed-matches/pubg" element={<ListMissedPubgMatches/>} />
            <Route path="/email-verification/:id" element={<RedoEmailVerification />}/>
            <Route path="/daily-challenges" element={<RedoDailyChallenges />}/>
            {/* <Route path="/:lang/email-verification/:id"element={<RedoEmailVerification />}/>  */}
            
            <Route path="/admin/daily-challenges" element={<AdminDailyChallenges />} />
            
            <Route path="/discord/oauth2" element={<DiscordOauth />} />

            <Route path="/admin/simulation/match-report" element={<SimMatchReport/>} />

          </Routes>
        </Suspense>
      </Router>
    );
  }
};

export default App;
