import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap/dist/js/bootstrap.min.js";
import "./assets/style/style1.scss";
import "./assets/style/style2.scss";
import "./assets/style/style3.scss";
import "./assets/style/style4.scss";
import "./assets/style/style4.scss";
import "./assets/style/style5.scss";
import "./assets/style/style6.scss";
import "./assets/style/style7.scss";
import "./assets/style/betting.scss";
import "./assets/style/admin.scss";
import "./assets/style/home.scss";
import "./assets/style/tournament.scss";


import "./assets/style/redo/header.scss";
import "./assets/style/redo/login.scss";
import "./assets/style/redo/twofactor.scss";
import "./assets/style/redo/footer.scss";
import "./assets/style/redo/tournaments.scss";
import "./assets/style/redo/settings.scss";
import "./assets/style/redo/dashboard.scss";
import "./assets/style/redo/bettings.scss";
import "./assets/style/redo/common.scss";
import "./assets/style/redo/affiliates.scss";
import "./assets/style/redo/transaction.scss";
import "./assets/style/redo/myFriends.scss";
import "./assets/style/redo/explore.scss";
import "./assets/style/redo/forgot.scss";
import "./assets/style/redo/steam.scss";
import "./assets/style/redo/dailychallenges.scss";
import "./assets/style/redo/claimReward.scss";
import "./assets/style/redo/datepicker.scss";
ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
